.card3{
    width: 190px !important;
    height: 200px;
    font-size: 0.85rem; /* Smaller font size */
    padding: 0px; /* Reduced padding */
    margin-top: 15px; /* Reduced margin between cards */
    height: 260px; /* Adjusted height to make the card smaller */
    overflow: hidden; /* Ensure content doesn't overflow */
    border-radius: 10px;
    box-shadow: rgb(200, 0, 255) 0px 20px 30px -10px;
}