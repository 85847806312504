.row{
    margin: 5px;
}
.card2 {
    font-size: 0.85rem; /* Smaller font size */
    padding: 0px; /* Reduced padding */
    margin-top: 15px; /* Reduced margin between cards */
  /* Adjusted height to make the card smaller */
    overflow: hidden; /* Ensure content doesn't overflow */
    border: 0.1px solid black;
    border-radius: 10px;
    box-shadow: rgb(0, 229, 255) 0px 20px 30px -10px;
    width: 200px;
    background-color: rgb(255, 255, 255);

}

.card-body {
    padding: 10px; /* Reduced padding inside the card */
    margin-top: 10px;
}
.modal .photo-slide{
    height: 220px;
}
.card-text {
    font-size: 0.8rem; /* Smaller text */
    margin-bottom: 3px; /* Reduced spacing between text elements */
    color: black;
    margin-top: 5px;
}

.modal-body {
    font-size: 0.9rem; /* Smaller font size in modal */
    padding: 10px; /* Reduced padding in modal */
}

.modal-title {
    font-size: 1.2rem; /* Smaller title in modal */
}

.button1 {
    background-color: #198754;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
    margin: 2px;
    font-size: 0.8rem;
    margin-left: 8px;
}

.button2 {
    background-color: #0d6efd;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
    border: none;
    font-size: 0.8rem;
}

/* Add this CSS */
.card-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.card-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
}

.photo-slide {
    height: 120px;
    width: 100%;
}

.img-fluid {
    width: 100%;
    height: 100% !important;
}
.carousel-item{
    height: 400px;
}

@media (max-width: 768px) {

    .card2 {
        font-size: 0.85rem; /* Smaller font size */
        padding: 0px; /* Reduced padding */
        margin: 0px;
        margin-bottom: 0px; /* Reduced margin between cards */
        min-height: 250px; /* Ensures the card is at least 250px tall */
        max-height: 320px; /* Ensures the card is no taller than 300px */
        overflow: hidden; /* Prevents content from overflowing the card */
        border: 0.1px solid rgb(0, 0, 0);
        border-radius: 10px;
        box-shadow: rgb(0, 229, 255) 0px 20px 30px -10px;
        width: 100%;
        background-color: rgb(255, 255, 255);
    }

    .card-text {
        font-size: 0.8rem; /* Smaller text */
        margin-bottom: 3px; /* Reduced spacing between text elements */
    }

    .modal-body {
        font-size: 0.9rem; /* Smaller font size in modal */
        padding: 5px; /* Reduced padding in modal */
    }
    .modal .photo-slide{
        height: 220px;
    }
    .modal-title {
        font-size: 1.2rem; /* Smaller title in modal */
    }

    .button1,
    .button2 {
        padding: 5px;
        border-radius: 5px;
        margin: 2px;
        font-size: 0.8rem;
    }



    /* No need to float, let Bootstrap handle the layout */
    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0px;
        padding: 0px;
        margin-top: 10px;
        padding-bottom: 10px;
    }

    .card-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 1px;
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    }

    .card-container::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Webkit browsers */
    }

    .photo-slide {
        height: 120px;
        width: 100%;
    }

    .img-fluid {
        width: 100%;
        height: 100% !important;
    }
    .carousel-item{
        height: 200px;
    }
    .slick-dots{
        margin-top: 0px;
    }
    
}


/* Container for the card */
.flip-card{
    background-color: transparent;
    width: 200px;
    height: 200px;
    border: 0.8px solid black;
    margin: 10px;
    padding: 10px;
  }
  
