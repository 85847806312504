.sellers-profiles {
    text-align: center;
}

.sellers-scroll {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    overflow-x: auto;
    padding: 10px;
}

/* For tablets */
@media (min-width: 768px) {
    .sellers-scroll {
        grid-template-columns: repeat(2, 1fr); /* 2 cards on tablets */
    }
}

/* For larger tablets and laptops */
@media (min-width: 992px) {
    .sellers-scroll {
        grid-template-columns: repeat(3, 1fr); /* 3 cards on laptops */
    }
}

/* For desktops */
@media (min-width: 1200px) {
    .sellers-scroll {
        grid-template-columns: repeat(4, 1fr); /* 4 cards on larger screens */
    }
}

.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    overflow: hidden;
}
.card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}