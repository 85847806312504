.tabs-container {
    width: 100%;
    margin: 0 auto;
}

.nav-tabs {
    border-bottom: 1px solid #ddd;
}

.nav-item {
    margin-bottom: -1px;
}

.nav-tab {
    background-color: #f8f9fa;
    border: 1px solid transparent;
    border-radius: 0;
    color: #007bff;
    padding: 10px;
}

.nav-tab.active {
    color: #ffffff;
    background-color: #000000;
    border-color: #ddd #ddd #fff;
}

.tab-content {
    padding: 15px;
    border: 1px solid #ddd;
    border-top: none;
    background-color: #fff;
}