
  .car-name {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #007bff;

  }
  
  .car-price {
    font-size: 1rem;
    color: #28a745;
  }

  .photo-slide1{
    height: 200px;
    margin-left: 10px;
  }
  .car-card{
    width: 300px !important;
    border: 2px;
  }

.car-offers {
    position: relative;
}

.car-card {
    position: relative;
    padding: 10px;
    margin: 20px;
    width: 300px !important;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    background-color: aliceblue;
    box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
}

.slick-center .car-card {
    transform: scale(1.1); /* Scale up the center card */
}

.slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-slide1 img {
    width: 100%;
    height: auto;
}

.car-details {
    text-align: center;
    margin-top: 10px;
}

.section-title {
    text-align: center;
    margin-bottom: 20px;
}
.photo-slide1{
  height: 200px;
  margin-left: 10px;
}
.car-offers{
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
