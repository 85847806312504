.card1 {
    font-size: 0.85rem; /* Smaller font size */
    padding: 0px; /* Reduced padding */
    margin: 15px; /* Reduced margin between cards */
    height: 270px; /* Adjusted height to make the card smaller */
    overflow: hidden; /* Ensure content doesn't overflow */
    border: 0.1px solid black;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    width: 230px ;
    background-color: rgb(255, 255, 255);
    float: left;
}

.card-body {
    padding: 10px; /* Reduced padding inside the card */
    margin-top: 10px;
}
.modal .photo-slide{
    height: 220px;
}
.card-text {
    font-size: 0.8rem; /* Smaller text */
    margin-bottom: 3px; /* Reduced spacing between text elements */
    color: black;
    margin-top: 5px;
}

.modal-body {
    font-size: 0.9rem; /* Smaller font size in modal */
    padding: 10px; /* Reduced padding in modal */
}

.modal-title {
    font-size: 1.2rem; /* Smaller title in modal */
}

.button1 {
    background-color: #198754;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
    margin: 2px;
    font-size: 0.8rem;
    margin-left: 8px;
}

.button2 {
    background-color: #0d6efd;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
    border: none;
    font-size: 0.8rem;
}
.button3 {
    background-color: #fe0000;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 2px;
    border: none;
    font-size: 0.8rem;
}

/* Add this CSS */
.card-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.card-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
}

.photo-slide {
    height: 120px;
    width: 100%;
}

.img-fluid {
    width: 100%;
    height: 100% !important;
}

@media (max-width: 768px) {

    .card1 {
        font-size: 0.85rem; /* Smaller font size */
        padding: 0px; /* Reduced padding */
        margin: 0px;
        margin-bottom: 0px; /* Reduced margin between cards */
        height: 260px; /* Adjusted height to make the card smaller */
        overflow: hidden; /* Ensure content doesn't overflow */
        border: 0.1px solid rgb(255, 255, 255);
        border-radius: 10px;
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        width: 100%;
        background-color: rgb(255, 255, 255);

    }

    .card-body {
        padding: 5px; /* Reduced padding inside the card */
        margin-top: 5px;
    }

    .card-text {
        font-size: 0.8rem; /* Smaller text */
        margin-bottom: 3px; /* Reduced spacing between text elements */
    }

    .modal-body {
        font-size: 0.9rem; /* Smaller font size in modal */
        padding: 10px; /* Reduced padding in modal */
    }
    .modal .photo-slide{
        height: 220px;
    }
    .modal-title {
        font-size: 1.2rem; /* Smaller title in modal */
    }

    .button1,
    .button2 {
        padding: 5px;
        border-radius: 5px;
        margin: 2px;
        font-size: 0.8rem;
    }

    /* Ensure the grid layout works as expected */
    .col-lg-2, .col-md-2, .col-sm-6 {
        width: 47%;
        padding-right: 0;
        padding-left: 0;
        margin: 3px;
    }

    /* No need to float, let Bootstrap handle the layout */
    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .card-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 10px;
        -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    }

    .card-container::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Webkit browsers */
    }

    .photo-slide {
        height: 120px;
        width: 100%;
    }

    .img-fluid {
        width: 100%;
        height: 100% !important;
    }
}

.bike-gallery-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
}

.bike-gallery {
    display: flex;
    gap: 20px;
}

.bike-card {
    display: inline-block;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    width: 250px; /* Adjust the width as necessary */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.bike-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.bike-card h5 {
    font-size: 18px;
    margin-bottom: 8px;
}

.bike-card p {
    font-size: 14px;
    margin-bottom: 4px;
}

@media (max-width: 768px) {
    .bike-card {
        width: 180px;
    }
}

@media (max-width: 480px) {
    .bike-card {
        width: 140px;
    }
}