/* Add to your CSS file */
.photo-slider {
    margin-top: 20px;
}

.photo-slide img {
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.photo-slide img:hover {
    transform: scale(1.05);
}

.photo-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.photo-modal-content img {
    max-width: 100%;
    max-height: 90%;
}

.card4{
    width: 100%;
    background: rgba(132, 233, 136, 0.288);
    border: 0.5px solid black;
}